<template>
    <div :id="'chartsBox_'+uuid" class="chart_box" :index="type"></div>
</template>

<script>
import { getCurrentInstance, onMounted, ref, reactive, onBeforeUnmount, toRefs } from 'vue'
import * as utils from './common.js'
import 'echarts/lib/component/grid'
import 'echarts-wordcloud'
export default {
    name: "QEchart",
    props: {
        type: {},                       // 图表的类型
        subType: {},                    // 图表的子类型
        list: Array,                    // 图表数据
        legend: Array,                  // 图表图例
        baseSetting: {},                // 图表基础配置
        disLegend: Boolean,             // 是否显示图例
        smooth: Boolean,                // 折线是否平滑
        disShadow: Boolean,             // 折线图是否有阴影
        fontRotate: {},                 // 坐标轴文字倾斜角度
        markVal: {},                    // 平行线
        barWidth: { type: Number, default: 20 },            // 柱状图 -- 柱子宽度
        ishasBackground: { type: Boolean, default: false }, // 图表背景色
        isStack: Boolean,               // 是否堆叠
        hasLine: Boolean,               // 柱状折线双拼图
        shapeType: { type: String, default: "polygon" },    // 雷达图  "polygon"--矩形  "circle"--圆形
        hasPie: Boolean,                // 雷达图 环形边框
        xAxisName: {},                  // 坐标轴名称 x
        yAxisName: {},                  // 坐标轴名称 y
        xMin: Number,                   // x轴最小值
        xMax: Number,                   // x轴最大值
        yMin: Number,                   // y轴最小值
        yMax: Number,                   // y轴最大值
        hasDiff: Boolean,               // 
        isHasScatter: Boolean,          // 
        hasBiaxial: Boolean,            // 
        showLabel: Boolean,             // 显示节点文本
    },
    setup(props){
        const uuid = ref(utils.uuid())
        let defaultSetting = reactive(props.baseSetting) // 默认配置
        // defaultSetting = props.baseSetting
        const defaultcolor = reactive(["#ffa20c", "#ff7b2f", "#72ce3e", "#00cd86", "#0cb5ff", "#4b93ff", "#6572ff", "#7a56ff", "#c359ff"])
        const randomIndex = reactive([])
        let globalProperties = reactive({})
       
        onMounted((e) => {
            echartRender()
        })
        onBeforeUnmount(() => {
            window.removeEventListener("resize",() => {})
        })

        const echartRender = async () => {
            let internalInstance = getCurrentInstance()
            globalProperties = internalInstance?.appContext.config.globalProperties

            let myChart = globalProperties.$echarts.init(document.getElementById('chartsBox_'+uuid.value))
            let seriesData = [], option = {}, datachart = {};
            props.legend && props.legend.map((item)=>{
                let randomInd = random()
                if(!item.color) item.color = defaultcolor[randomInd]
            })

            let tooltip = {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    shadowStyle:{
                        color: 'rgba(57,100,247,0.1)',
                    },
                    z:1
                },
                formatter: (params)=>{
                    if(props.type == 'line') params = params.filter(val => {return val.componentSubType =='line'})
                    let item = params[0]
                    if(params.length==1) {
                        if(item.componentSubType == 'boxplot'){
                            return '<div class="tooltip_title">'+item.name+'</div>'+
                                    '<span>最高值'+item.value[5]+'</span><br>'+
                                    '<span>中位值'+item.value[3]+'</span><br>'+
                                    '<span>最低值'+item.value[0]+'</span>'
                        }
                        if(item.componentSubType == 'scatter'){
                            return 'item.name'+'<br><span class="tooltip_span">'+item.value[1]+'</span>'
                        }
                        return item.name+'<br><span class="tooltip_span">'+item.value+'</span>'
                    }else{
                        let spanVal = ''
                        params.forEach((every,index) => {
                            let color = every.color
                            if(color.colorStops) color = color.colorStops[0].color
                            spanVal +='<br><span><i class="tooltip_icon" style="background:'+color+'"></i>'+every.seriesName+"："+every.value+'</span>'
                        })
                        return '<span class="tooltip_title">'+item.name+'</span>'+spanVal
                    }
                },
            }
            switch (props.type) {
                case 'radar':
                    datachart = setRadar()
                    tooltip = datachart.tooltip
                    break;
                case 'line':
                    datachart = setLine()
                    break;
                case 'bar':
                    datachart = setBar()
                    break;
                case 'scatter':
                    datachart = setScatter()
                    tooltip = datachart.tooltip
                    break;
                case 'wordCloud':
                    datachart = setWordCloud()
                    break;
                case 'pie':
                    datachart = setPie()  
                    tooltip = {
                        trigger: 'item',
                    }
                    break;
            }

            option = datachart.option
            seriesData = datachart.series
            if(props.markVal && seriesData[0]) {
                let markVal = utils.isArray(props.markVal) ? props.markVal : [ props.markVal ];
                let markValData=[]
                markVal.forEach(item => {
                    let obj = utils.isObject(item) ? item : {y: item};
                    let pushObj = {}
                    if(obj.x) markValData.push({ xAxis: obj.x, name: '对比数'})
                    if(obj.y) markValData.push({ yAxis: obj.y, name: '对比数'})
                })
                seriesData[0].markLine = {
                    silent: true,
                    data: markValData,
                    symbol:"none",
                    label:{
                        show: false
                    },
                    lineStyle:{
                        color: "rgba(219,222,229,0.8)",    //#168dff
                        width: 2,
                    },
                }
            }
            if(props.isStack){
                seriesData.forEach((item,index) => {
                    item.stack = "总量"
                })
            }
            if(props.showLabel){
                let label = {}
                seriesData.forEach((item,index) => {
                    if(item.type == 'pie'){
                        label = {
                            formatter: '{b}({d}%)'
                        }
                    }
                    if(item.type == 'bar'){
                        label = {
                            position: props.isStack || props.subType == 'horizontal' ?  'inside' : 'top',
                            color: props.isStack == 1 ? '#fff' : "",
                            fontSize: 12
                        }
                    }
                    item.label = {
                        show: true,
                        position: 'top',
                        ...label
                    }
                })
            }

            if(defaultSetting.seriesData){
                seriesData = [...seriesData, ...setDefaultSeries()]
            }
            let chartOption = {
                title: {
                    ...defaultSetting.title
                },
                legend: {
                    show: props.disLegend ? true : false,
                    pageIconColor:'#747e93',
                    pageIconInactiveColor:'#747e93',
                    pageIconSize:12,
                    pageTextStyle:{
                        color: '#747e93',
                    },
                    ...defaultSetting.legend,
                },
                textStyle: {
                    color: '#747e93',
                    fontSize: 16,
                    ...defaultSetting.textStyle
                },
                grid: {
                    top: 40,
                    bottom: 100,
                    left: 40,
                    right: 40,
                    ...defaultSetting.grid
                },
                tooltip:{
                    backgroundColor: "#ffffff",
                    padding: 16,
                    textStyle:{
                        color:"#333333",
                        lineHeight: 30,
                    },
                    extraCssText: 'box-shadow: 0 1px 10px rgba(59, 100, 168, 0.2); max-width: 50%; min-height:90px; overflow: hidden; white-space:normal;',
                    ...tooltip,
                    ...defaultSetting.tooltip
                },
                toolbox: {
                    top: '5',
                    right: '10',
                    ...defaultSetting.toolbox
                },
                ...option,
                graphic: defaultSetting.graphic,
                series: seriesData
            };


            // console.log(JSON.stringify(chartOption,89999))
            myChart.setOption(chartOption)
            window.addEventListener("resize", () => {
                myChart.resize()
            })
        }
        // xAxis yAxis 直角坐标系x轴,y轴配置
        const setAxis = () => {
            let dataAxis = props.list.map(({name}) => name)
            let xAxis = {
                type: 'category',
                data: dataAxis,
                name: ""
            }
            let yAxis = {}
            if(props.subType == 'horizontal') {
                xAxis = {
                    type: 'value'
                }
                yAxis = {
                    type: 'category',
                    data: dataAxis
                }
            }

            // 判断是否双 Y 轴
            if(Array.isArray(defaultSetting.yAxis)){
                yAxis = defaultSetting.yAxis
            }else{
                yAxis = {
                    ...yAxis,
                    ...defaultSetting.yAxis
                }
            }

            // 返回坐标轴配置
            return {
                xAxis: {
                    ...xAxis,
                    ...defaultSetting.xAxis
                },
                yAxis: yAxis,
                dataZoom: defaultSetting.dataZoom
            }

        }

        // 折线图
        const setLine = () => {
            let option, visualMap, seriesData = [],dataInfo = {}
            let len = props.list[0].value.length  
            option = setAxis()

            let seriesItem = {
                type: 'line',
                symbol:'circle',
                symbolSize: 10,
                smooth: props.smooth,
                itemStyle:{
                    borderColor:'#fff',
                    borderWidth:3
                },
                lineStyle:{
                    width:4,
                },
                ...defaultSetting.seriesItem
            }
            if(!props.disShadow){
                seriesItem.lineStyle.shadowBlur = 16
                seriesItem.lineStyle.shadowOffsetY = 5
            }
           
            for(let i=0; i<len; i++){
                let seriesItemSet = null
                dataInfo[i] = props.list.map(item => {
                    if(item.seriesItem) seriesItemSet = item.seriesItem[i]
                    return item.value[i]
                })
                let { color , areaStyleColor } = props.legend[i]

                let itemStyle = {
                    ...seriesItem.itemStyle,
                    color: color
                }
                let isShadow= areaStyleColor?areaStyleColor:"#fff"
                let lineStyle = {
                    ...seriesItem.lineStyle,
                    shadowColor: isShadow.colorRGBA(.4)
                }
                let areaStyle = seriesItem.areaStyle ? seriesItem.areaStyle : {
                    opacity:.2,
                    color: new globalProperties.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: isShadow.colorRGBA()
                    }, {
                        offset: .8,
                        color: isShadow.colorRGBA(0)
                    }])
                }
                if(seriesItemSet){
                    seriesItem = {
                        ...seriesItem,
                        ...seriesItemSet
                    }
                }
                seriesData.push({...seriesItem, itemStyle, lineStyle, areaStyle, data:dataInfo[i],name:props.legend[i].name})
               
            }

            if(props.isHasScatter){
                let scatterData = []
                props.list.forEach((item, index) => {
                    let value = item.value[0]
                    let spec = props.legend.filter(item =>{ return value<=item.max&&value>item.min})[0]
                    scatterData.push({value:'-'})
                    if(spec){
                        let {color,isSign} = spec
                        scatterData[index] = {value:value,color:color,isSign:isSign}
                    }
                });
                seriesData.push({
                    type: 'scatter',
                    data: scatterData.map(({value}) => value),
                    symbolSize:24,
                    itemStyle:{
                        color:(params) =>{
                            let {dataIndex} = params
                            return scatterData[dataIndex].color
                        },
                        opacity:1,
                        borderColor:'#fff',
                        borderWidth:6,
                    },
                    z:2,
                })
            }
            
            if(props.hasDiff){
                visualMap = {
                    type: 'piecewise',
                    show: false,
                    dimension: 0,
                    seriesIndex: 0,
                    pieces:[],
                }
                let merge = {}
                props.list.forEach((item, index) => {
                    let spec = props.legend.filter(itemled =>{ return item.key&&item.key==itemled.key})[0]
                    if(spec){
                        let {color,isSign,name} = spec
                        item.itemcolor = color
                        item.color = isSign?color:'#747e93'
                    }
                    if(!merge[item.key]){
                        merge[item.key]={
                            color: item.itemcolor,
                            gte: index-1,
                            lte: index,
                        }
                    }
                    merge[item.key].lte = index
                })
                visualMap.pieces = Object.values(merge); // list 转换成功的数据  

                option = {...option,visualMap}
            }

            if(props.hasBiaxial){
                let lineLeg = props.legend.filter(item => {return item.type == 'Line'})
                let barLineSeriesItem = defaultSetting.barLineSeriesItem || {}
                for(let i=0; i<lineLeg.length; i++){
                    let itemStyle ={}
                    let lineInfo = props.list.map(item => {
                        return item.lineval[i]
                    })
                    seriesData.push({
                        ...defaultSetting.seriesItem,
                        type: 'line',
                        data:lineInfo,
                        name:lineLeg[i].name,
                        symbolSize:15,
                        symbol:'circle',
                        ...barLineSeriesItem,
                        lineStyle:{
                            width:4,
                            shadowColor: 'rgba(253, 182, 40, .4)',
                            shadowBlur: 16,
                            shadowOffsetY:5,
                            ...(barLineSeriesItem && barLineSeriesItem.lineStyle ? barLineSeriesItem.lineStyle : {})
                        },
                        itemStyle:{
                            color: lineLeg[i].color || '#ff7b2f',
                            borderColor:'#fff',
                            borderWidth:5,
                            ...(barLineSeriesItem && barLineSeriesItem.itemStyle ? barLineSeriesItem.itemStyle : {})
                        },
                        yAxisIndex: i+1,
                        
                    })
                }
            }
            
            return { option, series: seriesData} 
        }

        // 柱状图
        const setBar = () => {
            let seriesData = [],dataInfo = {} 
            if(props.list && props.list.length){
                let len = props.list[0].value.length
                let seriesItem = {
                    type: 'bar',
                    barWidth: props.barWidth,
                    itemStyle: {
                        barBorderRadius: props.isStack || props.subType == 'horizontal'?[2, 2, 0, 0]:[8, 8, 0, 0]
                    },
                    showBackground: props.ishasBackground,
                    backgroundStyle:{
                        color:'#f4f5f9',
                        barBorderRadius: [5, 5, 0, 0]
                    },
                    ...defaultSetting.seriesItem
                }
                
                
                if(len==1){
                    props.list.forEach((item, index) => {
                        let value = item.value[0]
                        let spec = props.legend.filter(item =>{ return value<=item.max&&value>item.min})[0]
                        if(spec && !item.key){
                            let {itemcolor,color,isSign} = spec
                            item.itemcolor = itemcolor?itemcolor:[color,color]
                            item.color = isSign?color:'#747e93'
                        }
                    });
                }

                let realList = props.list;
                // 如果是动态数据就分段给出数据结果
                // if(this.dynamic){
                //     if(this.dynamicIndex > this.list.length-this.dynamic.maxList){
                //         this.dynamicIndex = 0
                //     }
                //     realList = realList.splice(this.dynamicIndex,this.dynamic.maxList);
                //     realList.reverse();
                // }

                for(let i=0; i<len; i++){
                    dataInfo[i] = realList.map(item => {
                        return item.value[i]
                    })
                    let itemStyle = {
                        ...seriesItem.itemStyle,
                        color: props.legend[i].color,
                        shadowColor: props.disShadow ? props.legend[i].color : false,
                        shadowBlur: props.disShadow ? 5 : false
                        
                    }
                    seriesData.push({...seriesItem, itemStyle, data:dataInfo[i],name:props.legend[i].name})
                   
                }
                
                if(props.hasLine){
                    let lineLeg = props.legend.filter(item => {return item.type == 'Line'})
                    let barLineSeriesItem = defaultSetting.barLineSeriesItem || {}
                    for(let i=0; i<lineLeg.length; i++){
                        let itemStyle ={}
                        let lineInfo = props.list.map(item => {
                            return item.lineval[i]
                        })
                        seriesData.push({
                            type: 'line',
                            data:lineInfo,
                            name:lineLeg[i].name,
                            symbolSize:15,
                            symbol:'circle',
                            ...barLineSeriesItem,
                            lineStyle:{
                                width:4,
                                shadowColor: 'rgba(253, 182, 40, .4)',
                                shadowBlur: 16,
                                shadowOffsetY:5,
                                ...(barLineSeriesItem && barLineSeriesItem.lineStyle ? barLineSeriesItem.lineStyle : {})
                            },
                            itemStyle:{
                                color: lineLeg[i].color,
                                borderColor:'#fff',
                                borderWidth:5,
                                ...(barLineSeriesItem && barLineSeriesItem.itemStyle ? barLineSeriesItem.itemStyle : {})
                            },
                            
                        })
                    }
                }
            }
            return { option: setAxis(), series: seriesData}
        }

        // 雷达图
        const setRadar = () => {
            let option, tooltip, seriesData = [], dataInfo = {};
            let len = props.list[0].value.length
            let dataAxis = props.list.map(item => {
                return {name: item.name, max: item.radarMax,axisLabel:{...item.axisLabel},axisTick:{...item.axisTick},axisLine:{...item.axisLine}}
            })
            option = {
                radar:{
                    center: ['50%', '50%'],
                    radius: '60%',
                    // name: {
                    //     textStyle: this.shapeType=='polygon'?{
                    //         backgroundColor: '#f3f5f7',
                    //         borderRadius: 32,
                    //         padding: [8, 16]
                    //     }:{}
                    // },
                    splitNumber: 4,
                    shape: props.shapeType,      
                    indicator: dataAxis,
                    ...defaultSetting.radar
                }
            }
            tooltip = {
                formatter: (params)=>{
                    let {value, name} = params
                    let spanVal = ''
                    value.length && 
                    value.forEach((every,index) => {
                        spanVal +='<br><span>'+dataAxis[index].name+"："+every+'</span>'
                    })
                    return '<span class="tooltip_title">'+name+'</span>'+spanVal
                },
            }
            
            let seriesItem = {
                type: 'radar',
                symbolSize: 12,
                lineStyle: {
                    width: 2,
                },
                ...defaultSetting.seriesItem
            }
            for(let i=0; i<len; i++){
                dataInfo[i] = props.list.map(item => {
                    return item.value[i]
                })
                seriesData.push({
                    value: dataInfo[i],
                    name: props.legend[i].name,
                    lineStyle: {
                        color: props.legend[i].color
                    },
                    itemStyle: {
                        color: props.legend[i].color,
                        borderColor:props.legend[i].color
                    },
                    areaStyle: {
                        color: props.legend[i].color.colorRGBA(.2)
                    }
                })
            }

            let pieObj = []
            if(props.hasPie){ 
                pieObj.push({
                    ...defaultSetting.pieSeriesItem
                })
            }

            return { option, tooltip, series: [{...seriesItem, data: seriesData}, ...pieObj] }
        }

        // 饼图
        const setPie = () => {
            let option, seriesData = [];
            option = {}
            let seriesItem = {
                type: 'pie',
                radius:'55%',
                ...defaultSetting.seriesItem
            }
            let itemStyle = {
                color: (params) =>{
                    let {dataIndex} = params
                    let { itemcolor, color } = props.list[dataIndex]
                    
                    if(!color){
                        let i = dataIndex%3
                        itemcolor = props.legend[dataIndex]?props.legend[dataIndex].itemcolor:props.legend[i].itemcolor
                        color = props.legend[dataIndex]?props.legend[dataIndex].color:props.legend[i].color
                    }
                    if(!itemcolor) itemcolor = color
                    return itemcolor
                },
                ...defaultSetting.itemStyle
            }

            if(props.subType == 'annular'){          //环形图
                let defaultSeriesItem = defaultSetting.seriesItem
                seriesItem.radius = defaultSeriesItem && defaultSeriesItem.radius ? defaultSeriesItem.radius : ['50%', '70%']
            }else if(props.subType == 'rose'){          //环形图
                let defaultSeriesItem = defaultSetting.seriesItem
                seriesItem.radius = defaultSeriesItem && defaultSeriesItem.radius ? defaultSeriesItem.radius : ['20%', '55%']
                seriesItem.roseType = 'radius'
            }
            props.list.forEach(item => {
                item.itemStyle = {
                    shadowColor: item.color
                }
            })
            
            seriesData = [{
                ...seriesItem,
                itemStyle,
                data: props.list
            }]

            return { option, series: seriesData }
        }

        // 散点图
        const setScatter = () => {
            let option, tooltip, seriesData = [], dataInfo = {};
            let len = props.list[0].value.length

            let dataAxis = props.list.map(({name}) => name)
            option = {
                xAxis: {
                    name: props.xAxisName,
                    max: props.xMax,
                    min: props.xMin,
                    axisTick: {
                        lineStyle:{
                            color:'#010101',
                            opacity:'.2'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#F9F9FD'
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    ...defaultSetting.xAxis,
                },
                yAxis: setAxis().yAxis
            }
            let seriesItem = {
                type: 'scatter',
                symbolSize: 14,
                itemStyle:{
                    shadowBlur: 10,
                    shadowOffsetY: 4,
                },
                label:{
                    show: true,
                    position: "bottom",
                    color:"#191919",
                    offset:[0,5],
                    formatter:'{b}'
                }
            }

            if(props.hasDiff){
                let normal = props.legend.filter(itemled =>{ return !itemled.key})[0]
                props.list.forEach((item, index) => {
                    item.color = normal?normal.color:''
                    let spec = props.legend.filter(itemled =>{ return item.key&&item.key==itemled.key})[0]
                    if(spec){
                        let {color,isSign} = spec
                        item.itemcolor = color
                        item.color = isSign?color:'#747e93'
                    }
                })
            }
            for(let i=0; i<len; i++){
                dataInfo[i] = props.list.map(item => {
                    return item.value[i]
                })
                dataInfo[i].forEach((item,index) => {
                    let { color, itemcolor } = props.list[index]
                    if(!color){
                        color = props.legend[i].color
                        itemcolor = color
                    }
                    
                    seriesData.push({
                        value: item,
                        itemStyle: {
                            shadowColor: itemcolor.colorRGBA(.6),
                            color: itemcolor
                        },
                        name: props.list[index].name
                    })
                });
            }
            tooltip = {
                trigger: 'item',
                axisPointer: {
                    type: 'shadow',
                    shadowStyle:{
                        color: 'rgba(57,100,247,0.1)',
                    },
                    z:1
                },
                formatter: (params)=>{ 
                    return params.name+'<br><span class="tooltip_span">'+params.value[1]+'</span>分'
                },

            }
            return { option, tooltip, series: [{...seriesItem, data: seriesData}] }
        }

        const random = () => {
            let len = defaultcolor.length
            if(randomIndex.length == len) {
                randomIndex = []
            }
            let num = Math.floor(Math.random()*len)
            let index = randomIndex.findIndex(item => { return item==num})
            
            if(index<0){
                randomIndex.push(num)
                return num
            } else{
                return random()
            }
            
        }
        const setWordCloud = ()=> {
            let option, seriesData = [];
            option = {}

            let seriesItem = {
                type: 'wordCloud',
                ...defaultSetting.seriesItem
            }
            seriesData = props.list
            return {option,series:[{...seriesItem,data: seriesData}]}
        }
        const setDefaultSeries = ()=>{
            let seriesData = defaultSetting.seriesData
            seriesData.forEach((item,index) => {
                if(item.type == 'pie'){
                    item.itemStyle = {
                        color: (params) =>{
                            let {dataIndex} = params
                            let { color } = this.legend[dataIndex]
                            return color
                        },
                    }

                }
            })
            return seriesData
        }
        return {
            uuid,
        }
    },
}
</script>

<style lang="scss" scoped>
.chart_box {
    width: 100%; height: 100%;

}
</style>