import {setToken, setRefreshToken, removeToken, removeRefreshToken,getProjectIndex,setProjectIndex} from '@/util/auth'
import {setToken as setTokenZyxt, setRefreshToken as setRefreshTokenZyxt, removeToken as removeTokenZyxt, removeRefreshToken as removeRefreshTokenZyxt} from '@/util/auth_zyxt'
import {setToken as setTokenZhpj,setRefreshToken as  setRefreshTokenZhpj, removeToken as removeTokenZhpj, removeRefreshToken as removeRefreshTokenZhpj} from '@/util/auth_zhpj'
import common,{ getLocal } from '@/assets/js/common.js'
import router from '../../router/router'
import {setStore, getStore} from '@/util/store'
import {Notify, Toast} from 'vant';
import {
    loginByPhone2,
    logout,
    refreshToken,
    dingtalkLogin
} from '@/api/user'

const user = {
    state: {
        projectIndex: getProjectIndex() || -1,

        tenantId: getStore({name: 'tenantId'}) || '',
        userInfo: getStore({name: 'userInfo'}) || [],
        token: getStore({name: 'token'}) || '',
        refreshToken: getStore({name: 'refreshToken'}) || '',

        tenantId1: getStore({name: 'zyxt-tenantId'}) || '',
        userInfo1: getStore({name: 'zyxt-userInfo'}) || [],
        token1: getStore({name: 'zyxt-token'}) || '',
        refreshToken1: getStore({name: 'zyxt-refreshToken'}) || '',

        tenantId2: getStore({name: 'zhpj-tenantId'}) || '',
        userInfo2: getStore({name: 'zhpj-userInfo'}) || [],
        token2: getStore({name: 'zhpj-token'}) || '',
        refreshToken2: getStore({name: 'zhpj-refreshToken'}) || '',
        childInfo: getStore({name: 'zhpj-childInfo'}) || '',
        childList: getStore({name: 'zhpj-childList'}) || [],
        classInfo: getStore({name: 'zhpj-classInfo'}) || '',
        classList: getStore({name: 'zhpj-classList'}) || '',
        reportInfo: getStore({name: 'zhpj-reportInfo'}) || '',
    },
    actions: {
        //刷新token
        refreshToken({state, commit}) {
            window.console.log('handle refresh token')
            return new Promise((resolve, reject) => {
                refreshToken(state.refreshToken, state.tenantId).then(res => {
                    const data = res.data;
                    commit('SET_TOKEN', data.access_token);
                    commit('SET_REFRESH_TOKEN', data.refresh_token);
                    resolve();
                }).catch(error => {
                    reject(error)
                })
            })
        },
        //钉钉自动登录
        DingtalkLogin({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                dingtalkLogin(userInfo.tenantId, userInfo.dingtalkAuthCode).then(res => {
                    const data = res.data;
                    const children = data.children;
                    if (data.error_description) {
                        Toast(data.error_description)
                        reject(data.error_description);
                    } else {
                        commit('SET_TOKEN', data.access_token);
                        commit('SET_REFRESH_TOKEN', data.refresh_token);
                        commit('SET_TENANT_ID', data.tenant_id);
                        let user_info = data;
                        delete user_info.children
                        commit('SET_USER_INFO', user_info);
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                })
            })
        },
        LoginByPhone2({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                loginByPhone2(userInfo.tenantId, userInfo.phone, userInfo.smsCode).then(res => {
                    const data = res.data;
                    const children = data.children;
                    console.log(data)
                    if (data.error_description) {
                        Notify({type: 'danger', message: data.error_description});
                        reject(data.error_description);
                    } else {
                        commit('SET_TOKEN', data.access_token);
                        commit('SET_REFRESH_TOKEN', data.refresh_token);
                        commit('SET_TENANT_ID', data.tenant_id);
                        let user_info = data;
                        delete user_info.children
                        commit('SET_USER_INFO', user_info);
                        console.log(user_info,'user_info')
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error);
                })
            })
        },
        // 登出
        LogOut({commit}) {
            return new Promise((resolve, reject) => {
                logout().then(() => {
                    commit('SET_TOKEN', '');
                    commit('SET_USER_INFO', {});
                    removeToken();
                    removeRefreshToken();
                    resolve();
                }).catch(error => {
                    reject(error)
                })
            })
        },
        SetProjectIndex({commit}, index) {
            return new Promise((resolve) => {
                commit('SET_PROJECT_INDEX',index);
                resolve();
            })
        },
        ResetUserInfo({commit}, userinfo) {
            console.info("ResetUserInfo",userinfo)
            return new Promise((resolve) => {
                commit('SET_USER_INFO',userinfo);
                resolve();
            })
        },
        SetCurType({commit}, type) {
            return new Promise((resolve) => {
                console.info("|||SetCurType",type)
                commit('SET_CUR_TYPE',type);
                resolve();
            })
        },
        SetChildInfo({commit},info){
            commit('SET_CHILD_INFO',info);
        },
        SetChildList({commit},info){
            commit('SET_CHILD_LIST',info);
        },
        SetClassInfo({commit},info){
            commit('SET_CLASS_INFO',info);
        },
        SetClassList({commit},info){
            commit('SET_CLASS_LIST',info);
        },
        SetReportInfo({commit},info){
            commit('SET_REPORT_INFO',info);
        },
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            const _projectIndex = getProjectIndex();
            if(_projectIndex === 1){
                setTokenZyxt(token);
                state.token1 = token;
                setStore({name: 'zyxt-token', content: state.token1})

            }else if(_projectIndex === 2){
                setTokenZhpj(token);
                state.token2 = token;
                setStore({name: 'zhpj-token', content: state.token2})
            }else{
                setToken(token);
                state.token = token;
                setStore({name: 'token', content: state.token})
            }
        },
        SET_REFRESH_TOKEN: (state, refreshToken) => {
            const _projectIndex = getProjectIndex();
            if(_projectIndex === 1){
                setRefreshTokenZyxt(refreshToken)
                state.refreshToken1 = refreshToken;
                setStore({name: 'zyxt-refreshToken', content: state.refreshToken1})

            }else if(_projectIndex === 2){
                setRefreshTokenZhpj(refreshToken)
                state.refreshToken2 = refreshToken;
                setStore({name: 'zhpj-refreshToken', content: state.refreshToken2})
            }else{
                setRefreshToken(refreshToken)
                state.refreshToken = refreshToken;
                setStore({name: 'zyxt-refreshToken', content: state.refreshToken})
            }

        },
        SET_TENANT_ID: (state, tenantId) => {
            const _projectIndex = getProjectIndex();
            if(_projectIndex === 1){
                state.tenantId1 = tenantId;
                setStore({name: 'zyxt-tenantId', content: state.tenantId1})
            }else if(_projectIndex === 2){
                state.tenantId2 = tenantId;
                setStore({name: 'zhpj-tenantId', content: state.tenantId2})
            }else{
                state.tenantId = tenantId;
                setStore({name: 'tenantId', content: state.tenantId})
            }
        },
        SET_CUR_TYPE: (state, type) => {
            if([1,3].includes(type)){
                const _projectIndex = getProjectIndex();
                if(_projectIndex === 1){
                    state.userInfo1 = Object.assign(state.userInfo1,{current_type:type})
                    setStore({name: 'zyxt-userInfo', content: state.userInfo1});
                    console.info("userInfo1",state.userInfo1)
                }else if(_projectIndex === 2){
                    state.userInfo2 = Object.assign(state.userInfo2,{current_type:type})
                    setStore({name: 'zhpj-userInfo',content: Object.assign(state.userInfo2,{current_type:type})})
                    console.info("userInfo2",state.userInfo2)
                }else{
                    state.userInfo = Object.assign(state.userInfo,{current_type:type})
                    setStore({name: 'userInfo', content: Object.assign(state.userInfo,{current_type:type})})
                    console.info("userInfo",state.userInfo)
                }
            }
        },
        SET_USER_INFO: (state, userInfo) => {
            const _projectIndex = getProjectIndex();
            if(_projectIndex === 1){
                state.userInfo1 = userInfo;
                setStore({name: 'zyxt-userInfo', content: state.userInfo1})

            }else if(_projectIndex === 2){
                state.userInfo2 = userInfo;
                setStore({name: 'zhpj-userInfo', content: state.userInfo2})

            }else{
                state.userInfo = userInfo;
                setStore({name: 'userInfo', content: state.userInfo})
            }

        },
        SET_PROJECT_INDEX: (state, index) => {
            state.projectIndex = index;
            setProjectIndex(index)
        },
        SET_CHILD_INFO: (state, info) => {
            state.childInfo = info;
            setStore({name: 'zhpj-childInfo', content: state.childInfo})
        },
        SET_CHILD_LIST: (state, info) => {
            state.childList = info;
            setStore({name: 'zhpj-childList', content: state.childList})
        },
        SET_CLASS_INFO: (state, info) => {
            state.classInfo = info;
            setStore({name: 'zhpj-classInfo', content: state.classInfo})
        },
        SET_CLASS_LIST: (state, info) => {
            state.classList = info;
            setStore({name: 'zhpj-classList', content: state.classList})
        },
        SET_REPORT_INFO: (state, info) => {
            state.reportInfo = info;
            setStore({name: 'zhpj-reportInfo', content: state.reportInfo})
        },
    },

}

export default user;
