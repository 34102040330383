import {isProduction} from './env'
/**
 * 全局配置文件
 */
export default {
  title: "saber",
  logo: "S",
  key: 'saber',//配置主键,目前用于存储
  indexTitle: '新生与准新生管理系统',
  clientId: 'saber', // 客户端id
  clientSecret: 'saber_secret', // 客户端密钥
  tenantMode: true, // 是否开启租户模式
  tenantId: "000000", // 管理组租户编号
  captchaMode: true, // 是否开启验证码模式
  userType:"app",
  tokenTime: 3000,
  tokenHeader: 'Blade-Auth',
  //http的status默认放行列表
  statusWhiteList: [],
  //配置首页不可关闭
  isFirstPage: false,
  fistPage: {
    label: "首页",
    value: "/wel/index",
    params: {},
    query: {},
    meta: {
      i18n: 'dashboard'
    },
    group: [],
    close: false
  },
  corpId:'ding9c13b4b41c075dde',
  //代理地址的前缀默认值'/api/upload',正式环境替换为''
  propsHttpPrefix:  isProduction ?  '' : '/',
  httpUrl:'https://house.121xuexi.com',//后台地址，用于拼接图片绝对地址
  isProduction
}
