import Echart from './echarts/index.vue'
const plugins = [
]
const components = [
    Echart
]
const install = (app) => {
    // 全局注册所有除了plugins之外的组件
    Object.keys(components).forEach(c => {
        app.component(components[c].name, components[c])
    })
    
    Object.keys(plugins).forEach(plugin => {
        app.use(plugins[plugin])
    })
}

export default install