import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import global from './util/global'
import func from "@/util/func";
// import lodash from 'lodash';
import {
    iconfontUrl,
    iconfontVersion
} from '@/config/env';
import {loadStyle} from './util/util'
// 动态加载阿里云字体库
iconfontVersion.forEach(ele => {
    loadStyle(iconfontUrl.replace('$key', ele));
});

import Vant from 'vant';
import vue3videPlay from 'vue3-video-play'
import 'vue3-video-play/dist/style.css'

import 'vant/lib/index.css';
// import './assets/iconfont/iconfont.css'             // 自定义图标库
import './assets/css/style.scss'
import './assets/css/edit.css'
import './assets/css/common.scss'

import * as echarts from 'echarts'
import globalEchart from './components'
// import Vconsole from 'vconsole'
const app = createApp(App)


app.config.globalProperties.$echarts = echarts
// let vConsole = new Vconsole()
app.use(vue3videPlay)
app.use(Vant)
app.use(router)
app.use(store)
app.use(globalEchart)
// app.use(vConsole)
app.provide('global',global);
app.config.globalProperties.$getUrl = func.getProxyUrl;
app.config.globalProperties.$formatNull = func.formatNullVal;

app.mount('#app')

