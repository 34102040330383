const jkmc = 'api' // 接口名称
const publicName = '' // 本地json地址的前缀
const headError = 'this.src="' + require('@/assets/image/default/defaultimg.png') + '"' //默认头像

const certifiedJkmc = '/api'

export const getLocal = (name) => {
    return localStorage.getItem(name)
}
export const setLocal = (name, value) => {
    localStorage.setItem(name, value)
}

export const getSortFun = (sortBy,order) => {
    let ordAlpah = (order == 'desc') ? '<' : '>';    //asc  desc
    let sortFun = new Function('a', 'b', 'return a.' + sortBy + ordAlpah + 'b.' + sortBy + '?1:-1');
    return sortFun;
}

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

// 获取地址栏参数信息
export const _getQueryId = (name) =>{
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', "i");
    const r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return '';
};

export const isObject = (o) => {
    return o == null ? false : Object.prototype.toString.call(o) === "[object Object]";
}
export const isArray = (a) => {
    return Object.prototype.toString.call(a) === "[object Array]";
}

// 生成UUID
export const UUID = (len = 256) => {    
    var lut = [];
    for (var i = 0; i < len; i++) {
        lut[i] = (i < 16 ? '0' : '') + (i).toString(16);
    }
    var d0 = Math.random() * 0xffffffff | 0;
    var d1 = Math.random() * 0xffffffff | 0;
    var d2 = Math.random() * 0xffffffff | 0;
    var d3 = Math.random() * 0xffffffff | 0;
    return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + '-' +
        lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + '-' + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + '-' +
        lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + '-' + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
        lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
}


// 导出文件
export const exportData = (res, filename) =>{
    var blob = new Blob([res], {
        type: res.type
    }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
    var downloadElement = document.createElement('a');
    var href = window.URL.createObjectURL(blob); //创建下载的链接
    downloadElement.href = href;
    downloadElement.download = filename; //下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放掉blob对
}
export default {
    jkmc,
    publicName,
    headError,
    certifiedJkmc,
}