const CurrentChild = 'current-child'
const ParentNodeList = 'parent-node-list'
const ChildNodeList = 'child-node-list'
const ViewMode = 'view-mode'
const CurMainId = 'cur-main-id'

export function setCurrentChild(currentChild) {
	removeCurrentChild();
	return localStorage.setItem(CurrentChild,JSON.stringify(currentChild))
}

export function removeCurrentChild() {
	return localStorage.removeItem(CurrentChild)
}

export function getCurrentChild() {
	return JSON.parse(localStorage.getItem(CurrentChild))
}

export function removeParentNodeList() {
	return localStorage.removeItem(ParentNodeList)
}

export function setParentNodeList(parentNodeList) {
	removeParentNodeList();
	return localStorage.setItem(ParentNodeList,JSON.stringify(parentNodeList))
}

export function getParentNodeList() {
	return JSON.parse(localStorage.getItem(ParentNodeList))
}


export function removeChildNodeList() {
	return localStorage.removeItem(ChildNodeList)
}

export function setChildNodeList(childNodeList) {
	removeChildNodeList();
	return localStorage.setItem(ChildNodeList,JSON.stringify(childNodeList))
}

export function getChildNodeList() {
	return JSON.parse(localStorage.getItem(ChildNodeList))
}

export function setViewMode(val) {
	removeViewMode();
	return localStorage.setItem(ViewMode,JSON.stringify(val))
}

export function removeViewMode() {
	return localStorage.removeItem(ViewMode)
}

export function getViewMode() {
	return JSON.parse(localStorage.getItem(ViewMode))
}

export function setCurMainId(val) {
	removeCurMainId();
	return localStorage.setItem(CurMainId,JSON.stringify(val))
}

export function removeCurMainId() {
	return localStorage.removeItem(CurMainId)
}

export function getCurMainId() {
	return JSON.parse(localStorage.getItem(CurMainId))
}

