/**
 * 全局变量,用于注入到组件使用
 * @author DJ.
 */

import * as dd from 'dingtalk-jsapi'
import {getENV} from 'dingtalk-jsapi/lib/env';
import {ImagePreview} from "vant";
const {platform, version, appType} = getENV();

const doPreviewImage = (images, index) => {
    ImagePreview({
        images: images,
        startPosition: index,
        closeable: true,
        getContainer: "body"
    });
}

export default {
    dd,
    dVersion:version,
    platform,
    appType,
    doPreviewImage
}