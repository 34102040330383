/**
 * 作业系统路由
 */

const Layout = () => import('@/page/layout/home')
export default [
    {
        path: '/zyxt/long-work',
        name: 'zy2-long-work',
        redirect: '/zyxt/long-work/my-index',
        component: () => import('@/components/layout/index.vue'),
        children: [
            {
                path: 'my-index',
                name: 'zy2-my-index',
                component: () => import('@/views/zyxt/zy2/myLongWorkListIndex'),
                meta: {
                    title: '长程作业申报',
                    requireAuth:true
                }
            },
            {
                path: 'detail-index/:id',
                name: 'zy2-detail-index',
                component: () => import('@/views/zyxt/zy2/longWorkDetailIndex'),
                meta: {
                    title: '长程作业详情',
                    requireAuth:true
                }
            },
            {
                path: 'add',
                name: 'zy2-add',
                component: () => import('@/views/zyxt/zy2/addLongWorkIndex'),
                meta: {
                    title: '长程作业申报',
                    requireAuth:true
                }
            },
            {
                path: 'view',
                name: 'zy2-view',
                component: () => import('@/views/zyxt/zy2/viewListIndex'),
                meta: {
                    title: '长程作业查看',
                    requireAuth:true
                }
            },
            {
                path: 'view-detail-index/:id',
                name: 'zy2-view-detail-index',
                component: () => import('@/views/zyxt/zy2/viewLongWorkDetailIndex'),
                meta: {
                    title: '长程作业详情',
                    requireAuth:true
                }
            },
            {
                path: 'mark-list-index',
                name: 'zy2-mark-list-index',
                component: () => import('@/views/zyxt/zy2/markListIndex'),
                meta: {
                    title: '长程作业评分',
                    requireAuth:true
                }
            },
            {
                path: 'student-work-list-index',
                name: 'zy2-student-work-list-index',
                component: () => import('@/views/zyxt/zy2/studentWorkListIndex'),
                meta: {
                    title: '长程作业',
                    requireAuth:true
                }
            },
            {
                path: 'student-work-detail-index/:id',
                name: 'zy2-student-work-detail-index',
                component: () => import('@/views/zyxt/zy2/studentLongWorkDetailIndex'),
                meta: {
                    title: '长程作业详情',
                    requireAuth:true
                }
            },
            {
                path: 'add-result0',
                name: 'zy2-add-result0',
                component: () => import('@/views/zyxt/zy2/addResult0'),
                props: route => ({
                    longWorkId: route.query.longWorkId,
                    isGroup: route.query.isGroup === '1'
                }),
                meta: {
                    title: '实践成果上传',
                    requireAuth:true
                }
            },
            {
                path: 'add-result1',
                name: 'zy2-add-result1',
                component: () => import('@/views/zyxt/zy2/addResult1'),
                props: route => ({
                    longWorkId: route.query.longWorkId,
                    isGroup: route.query.isGroup === '1'
                }),
                meta: {
                    title: '成果公开上传',
                    requireAuth:true
                }
            },
            {
                path: 'add-team',
                name: 'zy2-add-team',
                component: () => import('@/views/zyxt/zy2/addTeam'),
                props: route => ({
                    longWorkId: route.query.longWorkId
                }),
                meta: {
                    title: '维护团队',
                    requireAuth:true
                }
            },
            {
                path: 'student-result-page-index',
                name: 'zy2-student-result-page-index',
                component: () => import('@/views/zyxt/zy2/studentResultPageIndex'),
                props: route => ({
                    longWorkId: route.query.longWorkId,
                    studentId:route.query.studentId,
                    type:parseInt(route.query.type),
                }),
                meta: {
                    title: '长程作业成果',
                    requireAuth:true
                }
            },
            {
                path: 'score-teacher-index',
                name: 'zy2-score-teacher-index',
                component: () => import('@/views/zyxt/zy2/scoreTeacherIndex'),
                props: route => ({
                    longWorkId: route.query.longWorkId
                }),
                meta: {
                    title: '长程作业评分--老师',
                    requireAuth:true
                }
            },
            {
                path: 'score-student-index',
                name: 'zy2-score-student-index',
                component: () => import('@/views/zyxt/zy2/scoreStudentIndex'),
                props: route => ({
                    longWorkId: route.query.longWorkId
                }),
                meta: {
                    title: '长程作业评分--学生',
                    requireAuth:true
                }
            },
            {
                path: 'score-self-index',
                name: 'zy2-score-self-index',
                component: () => import('@/views/zyxt/zy2/scoreSelfIndex'),
                props: route => ({
                    longWorkId: route.query.longWorkId
                }),
                meta: {
                    title: '长程作业评分--学生自评',
                    requireAuth:true
                }
            },
            {
                path: 'score-detail-index',
                name: 'zy2-score-detail-index',
                component: () => import('@/views/zyxt/zy2/scoreDetailIndex'),
                props: route => ({
                    longWorkId: route.query.longWorkId
                }),
                meta: {
                    requireAuth:true
                }
            },
            {
                path: 'tj/tj1',
                name: 'zy2-tj1',
                component: () => import('@/views/zyxt/zy2/tj/tj1.vue'),
                meta: {
                    title: '多年级单学科同纬度',
                }
            },
            {
                path: 'tj/tj2',
                name: 'zy2-tj2',
                component: () => import('@/views/zyxt/zy2/tj/tj2.vue'),
                meta: {
                    title: '单年级单学科多纬度',
                }
            }
        ]
    }

]
