import {createRouter, createWebHistory} from 'vue-router'
import PageRouter from './page/' // 页面路由
import ViewsRouterZy from './views/zy' // 页面路由
import ViewsRouterZy2 from './views/zy2' // 页面路由
import ViewsRouterZhpj from './views/zhpj' // 页面路由
import store from '../store'
import * as dd from 'dingtalk-jsapi'
import {getENV,} from 'dingtalk-jsapi/lib/env';
const {platform, version, appType,} = getENV();
import 'nprogress/nprogress.css';
import NProgress from 'nprogress';
import {getChildNodeList, getParentNodeList} from '@/api/system'
import {getToken, getUser,setProjectIndex} from '@/util/auth'
import {getToken as getTokenZyxt} from '@/util/auth_zyxt'
import {getToken as getTokenZhpj} from '@/util/auth_zhpj'
import {Notify, Toast} from "vant";

const base = process.env.NODE_ENV === "production" ? "/" : "/"
const router = createRouter({
    history: createWebHistory(base), // hash模式：createWebHashHistory，history模式：createWebHistory
    routes: [//====requireNode:true时，路由名称（name）必须与数据库xs_node_point中的route字段相同====
        ...PageRouter,...ViewsRouterZy,...ViewsRouterZy2,...ViewsRouterZhpj,
        {
            path: '/',
            name: 'profile',
            redirect: '/index/choose-child',
            meta: { requireNode: false,requireAuth:false }
        },
        {
            path: '/index',
            name: 'home',
            redirect: '/index/choose-child',
            meta: { requireNode: false,requireAuth:false },
            component: () => import('@/components/layout/index.vue'),
            children: [
                {
                    path: 'login',
                    name: 'login',
                    component: () => import('@/views/login/login.vue'),
                    meta: { requireNode: false,requireAuth:false }
                }, {
                    path: 'choose-child',
                    name: 'chooseChild',
                    component: () => import('@/views/login/chooseChild.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                }, {
                    path: 'parent-instructions',
                    name: 'parentInstructions',
                    component: () => import('@/views/instruction/parentInstructions.vue'),
                    meta: { requireNode: true ,requireAuth:true}
                }, {
                    path: 'guidelines',
                    name: 'guidelines',
                    component: () => import('@/views/instruction/guidelines.vue'),
                    meta: { requireNode: true,requireAuth:true }
                }, {
                    path: 'notice/:v?',
                    name: 'notice',
                    component: () => import('@/views/instruction/notice.vue'),
                    meta: { requireNode: true ,requireAuth:true}
                }, {
                    path: 'camp',
                    name: 'camp',
                    component: () => import('@/views/instruction/camp.vue'),
                    meta: { requireNode: true ,requireAuth:true}
                }, {
                    path: 'opening-ceremony',
                    name: 'openingCeremony',
                    component: () => import('@/views/instruction/openingCeremony.vue'),
                    meta: { requireNode: true,requireAuth:true }
                }, {
                    path: 'other-ceremony',
                    name: 'otherCeremony',
                    component: () => import('@/views/instruction/otherCeremony.vue'),
                    meta: { requireNode: true,requireAuth:true }
                }, {
                    path: 'fee-detail',
                    name: 'feeDetail',
                    component: () => import('@/views/fee/feeDetail.vue'),
                    meta: { requireNode: true ,requireAuth:true}
                }, {
                    path: 'upload-screenshot',
                    name: 'uploadScreenshot',
                    component: () => import('@/views/fee/uploadScreenshot.vue'),
                    meta: { requireNode: true,requireAuth:true }
                }, {
                    path: 'esign/:signId?',
                    name: 'esign',
                    component: () => import('@/views/esign/index.vue'),
                    meta: { requireNode: true,requireAuth:true }
                }, {
                    path: 'service',
                    name: 'service',
                    component: () => import('@/views/service/index.vue'),
                    meta: { requireNode: true,requireAuth:true }
                },
            ]
        },
        {
            path: '/manage',
            name: 'manage',
            redirect: '/manage/index',
            component: () => import('@/components/layout/index.vue'),
            children: [
                {
                    path: 'index',
                    name: 'manageIndex',
                    component: () => import('@/views/manage/index.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'finance/index',
                    name: 'financeIndex',
                    component: () => import('@/views/manage/finance/index.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'finance/fee-list/:mainProcessId',
                    name: 'feeList',
                    component: () => import('@/views/manage/finance/feeList'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'sta/leader-index',
                    name: 'leaderIndex',
                    component: () => import('@/views/manage/sta/leaderIndex.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'process/index',
                    name: 'processIndex',
                    component: () => import('@/views/manage/process/index.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'process/process-list/:mainProcessId',
                    name: 'processList',
                    component: () => import('@/views/manage/process/processList'),
                    meta: { requireNode: false ,requireAuth:true}
                },
            ]
        },
        {
            path: '/zhpj',
            name: 'report',
            component: () => import('@/components/layout/index.vue'),
            children: [
                {
                    path: 'roleCheck',
                    name: 'roleCheck',
                    component: () => import('@/views/report/roleCheck.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'parentRole',
                    name: 'parentRoleIndex',
                    component: () => import('@/views/report/child.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'teacherRole',
                    name: 'teacherRoleIndex',
                    component: () => import('@/views/report/teacher.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'parent',
                    name: 'parentIndex',
                    component: () => import('@/views/report/parent/index.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'teacher',
                    name: 'teacherIndex',
                    component: () => import('@/views/report/teacher/index.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'classList',
                    name: 'classListIndex',
                    component: () => import('@/views/report/teacher/classList.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'class',
                    name: 'classIndex',
                    component: () => import('@/views/report/teacher/studentList.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'system',
                    name: 'systemIndex',
                    component: () => import('@/views/report/system/index.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'sysClassList',
                    name: 'sysClassListIndex',
                    component: () => import('@/views/report/system/classList.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'student',
                    name: 'studentIndex',
                    component: () => import('@/views/report/components/report.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'detail',
                    name: 'studentDetail',
                    component: () => import('@/views/report/components/reportModule.vue'),
                    meta: { requireNode: false ,requireAuth:true}
                },
                {
                    path: 'defineEvaluate',
                    name: 'defineEvaluate',
                    component: () => import('@/views/report/define/rate.vue'),
                    meta: { requireNode: false ,requireAuth:true,title:'Define me'}
                },
                {
                    path: 'defineStudent',
                    name: 'defineStudent',
                    component: () => import('@/views/report/define/studentList.vue'),
                    meta: { requireNode: false ,requireAuth:true,title:'选择同学'}
                },
                {
                    path: 'form',
                    name: 'formIndex',
                    component: () => import('@/views/report/form/index.vue'),
                    meta: { requireNode: false ,requireAuth:true,title:'成长规划'}
                },
                {
                    path: 'pdf',
                    name: 'pdfIndex',
                    component: () => import('@/views/report/components/viewpdf.vue'),
                    meta: { requireNode: false ,requireAuth:true,title:'学生综合素质报告书'}
                }
            ]
        }
    ]
})

// 路由守卫
router.beforeEach( (to, from, next) => {
    NProgress.start();
    const _toPath = to.fullPath||'';
    //作业系统
    const zyxtFlag = _toPath.substr(0,5) == '/zyxt';
    //综合素养评价系统
    const zhpjFlag = _toPath.substr(0,5) == '/zhpj';
    // console.info("before------------",zyxtFlag,zhpjFlag)

    if(zyxtFlag){
        // console.info("getTokenZyxt()",getTokenZyxt())
        // setProjectIndex(1)
        store.dispatch("SetProjectIndex", 1)
        const meta = to.meta || {};
        if (getTokenZyxt()) {
            if (to.path === '/zyxt-login') { //如果登录成功访问登录页跳转到主页
                next()
            } else {
                //如果用户信息为空则获取用户信息，获取用户信息失败，跳转到登录页
                if (getTokenZyxt().length === 0) {
                    store.dispatch('FedLogOut').then(() => {
                        next({path: '/zyxt-login'})
                    })
                } else {
                    next()
                }
            }
        } else {
            //判断是否需要认证，没有登录访问去登录页
            if (meta.requireAuth === false) {
                next()
            } else {
                next('/zyxt-login')
            }
        }

    }else if(zhpjFlag){
        // setProjectIndex(2)
        store.dispatch("SetProjectIndex", 2)
        // console.info("getTokenZhpj()",getTokenZhpj())
        const meta = to.meta || {};
        if(meta.title) document.title = meta.title
        if (getTokenZhpj()) {
            if (to.path === '/zhpj-login') { //如果登录成功访问登录页跳转到主页
                next()
            } else {
                //如果用户信息为空则获取用户信息，获取用户信息失败，跳转到登录页
                if (getTokenZhpj().length === 0) {
                    store.dispatch('FedLogOut').then(() => {
                        next({path: '/zhpj-login'})
                    })
                } else {
                    next()
                }
            }
        } else {
            //判断是否需要认证，没有登录访问去登录页
            if (meta.requireAuth === false) {
                next()
            } else {
                next('/zhpj-login')
            }
        }

    }else{
        //缴费系统
        // setProjectIndex(0)
        store.dispatch("SetProjectIndex", 0)
        if (to.path === '/index/login') return next();
        let user = getUser() || {};
        let type = user.type || 0;//1老师，2学生，3家长
        let currentType = user.currentType || 0;
        let _isParent = type === 3 || (type === 1 && currentType === 3);
        let _isTeacher = type === 1 && currentType !== 3;
        if (!(_isParent || _isTeacher) && !(to.path === '/index/login')) {
            Notify({type: 'warning', message: `目前只允许家长或老师登录`});
        }


        let hasRoute = router.hasRoute(to.name);
        if (!hasRoute) {
            if (_isParent) {
                return next({name: 'profile'})
            }
            if (_isTeacher) {
                return next({name: 'manage'})
            }
            return next({name: 'profile'})
        }

        let _toName = to.name;
        if (['home', 'chooseChild'].includes(_toName) && _isTeacher) {
            return next({name: 'manage'});
        }

        if (['manage', 'manageIndex'].includes(_toName) && _isParent) {
            return next({name: 'profile'});
        }

        let _requireAuth = to.meta.requireAuth;
        let _requireNode = to.meta.requireNode;
        if (_requireAuth) {
            let token = getToken();
            if (!token /*&& to.name !== 'login'*/) return next('/index/login');
        }

        if (_requireNode) {
            let childNodeList = getChildNodeList() || [];
            let parentNodeList = getParentNodeList() || [];
            if ((childNodeList.length === 0 || parentNodeList.length === 0)/* && (to.name !== 'chooseChild')*/) {
                return next("/index/choose-child");
            }
        }
        next();

    }
});


router.afterEach((to, from, failure) => {
    Notify.clear();
    Toast.clear(true);
    const _toPath = to.fullPath;
    //作业系统
    const zyxtFlag = _toPath.substr(0,5) == '/zyxt';
    //综合素养评价系统
    const zhpjFlag = _toPath.substr(0,5) == '/zhpj';
    if(!(zyxtFlag || zhpjFlag)){
        if(failure){
            Notify({type: 'warning', message: `抱歉，路由${from.name} 至 ${to.name}出错了，将跳转至首页。`});

            let user = getUser() || {};
            let type = user.type || 0;//1老师，2学生，3家长
            let currentType = user.currentType || 0;
            let _isParent = type === 3 ||  (type === 1 && currentType === 3);
            let _isTeacher = type === 1 && currentType !== 3;
            let _toName = to.name;
            if(!['profile','home','chooseChild','manage','manageIndex'].includes(_toName)){
                setTimeout(() => {
                    router.replace({name: (_isParent ? 'profile' : _isTeacher ? 'manage' : 'login')})
                }, 2000)
                //login不会执行到
            }
        }
    }

    /* 路由发生变化修改页面title */
    let _title = to.query.title  || to.meta.title;
    if (_title) {
        if(version){
            dd.biz.navigation.setTitle({
                title : _title,//控制标题文本，空字符串表示显示默认文本
            });
        }else{
            document.title = _title;
        }
    }else{
        if(version){
            dd.biz.navigation.setTitle({
                title : '',//控制标题文本，空字符串表示显示默认文本
            });
        }else{
            document.title = '';
        }
    }

    NProgress.done()
})


export default router
