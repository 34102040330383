const getters = {
    userInfo: state => {
        const _projectIndex = state.user.projectIndex || 0
        if (_projectIndex === 1) {
            return state.user.userInfo1
        } else if (_projectIndex === 2) {
            return state.user.userInfo2
        }else{
            return state.user.userInfo
        }
    },
    userType: state => {
        const _projectIndex = state.user.projectIndex || 0
        if (_projectIndex === 1) {
            return state.user.userInfo1.current_type
        } else if (_projectIndex === 2) {
            return state.user.userInfo2.current_type
        }else{
            return state.user.userInfo.current_type
        }

    },
    token: state => {
        const _projectIndex = state.user.projectIndex || 0
         if (_projectIndex === 1) {
            return state.user.token1
        } else if (_projectIndex === 2) {
            return state.user.token2
        }else {
            return state.user.token
        }
    },
    projectIndex: state => state.user.projectIndex,
    roles: state => {
        const _projectIndex = state.user.projectIndex || 0
        const data = [];
        let temp = [];
        if(_projectIndex === 1){
            temp = (state.user.userInfo1.role_name || '').split(',') || []
        }else if(_projectIndex === 2){
            temp =(state.user.userInfo2.role_name || '').split(',') || []
        }else{
            temp = (state.user.userInfo.role_name || '').split(',') || []
        }
        temp.forEach(ele => data.push(ele))
        return data;
    },
    currentChildInfo: state=>state.user.childInfo,
    childList: state=>state.user.childList,
    currentClassInfo: state=>state.user.classInfo,
    classList: state=>state.user.classList,
    reportInfo: state=>state.user.reportInfo,
};
export default getters
