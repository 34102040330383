export default [
    {
        path: '/zyxt-login',
        name: 'zyxt-login',
        component: () =>
            import( /* webpackChunkName: "page" */ '@/page/login/login'),
        props: route => ({
            formIndex: route.query.formIndex,
            identity: route.query.identity,
        }),
        meta: {
            keepAlive: false,
            requireAuth: false
        }
    },
    {
        path: '/zhpj-login',
        name: 'zhpj-login',
        props: route => ({
            formIndex: route.query.formIndex,
            identity: route.query.identity,
        }),
        component: () =>
            import( /* webpackChunkName: "page" */ '@/page/login/login_zhpj'),
        meta: {
            keepAlive: false,
            requireAuth: false
        }
    },
    {
        path: '/404',
        component: () =>
            import( /* webpackChunkName: "page" */ '@/components/error-page/404'),
        name: '404',
        meta: {
            keepAlive: true,
            requireAuth: false
        }

    },
    {
        path: '/403',
        component: () =>
            import( /* webpackChunkName: "page" */ '@/components/error-page/403'),
        name: '403',
        meta: {
            keepAlive: true,
            requireAuth: false
        }
    },
    {
        path: '/500',
        component: () =>
            import( /* webpackChunkName: "page" */ '@/components/error-page/500'),
        name: '500',
        meta: {
            keepAlive: true,
            requireAuth: false
        }
    },
    {
        path: '/zyxt',
        name: 'zyxt',
        redirect: '/zyxt-login'
    },
    {
        path: '/zhpj',
        name: 'zhpj',
        redirect: '/zhpj-login'
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }
]
