import request from '@/router/axios';
import axios from '@/router/axios';

export const getInsDetail = (id,signId) => request({
  url: '/api/nodepointins/detail-for-app',
  method: 'get',
  query: {},
  params: {
    id,
    signId
  }
});

export const handleSubmit = (row) => request({
  url: '/api/ins-student/handle-submit',
  method: 'post',
  data: row
});


export const getStudentInsDetail = (id,nodePointInsId,signId) => request({
  url: '/api/ins-student/student-ins-detail',
  method: 'get',
  params: {
    id,
    nodePointInsId,
    signId
  }
});

/**
 * 检查通知书
 * @param id feeId
 * @returns {AxiosPromise}
 */
export const checkAdmission = (id) => request({
  url: '/api/fee/check-admission',
  method: 'get',
  params: {
    id
  }
});


export const getQaPage = (current, size, params) => request({
  url: '/api/newstudent/qa/page',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  }
});

export const getAuditFeePage = (current, size, params) => request({
  url: '/api/fee/audit-page',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  }
});

//获取缴费的学年学期
export const getYearTermList = () => request({
  url: '/api/feeyear/year-term-dic2',
  method: 'get',
  params: {
  }
});

export const getMainProcessDic = () => request({
  url: '/api/newstudent/mainprocess/dic',
  method: 'get',
  params: {
  }
});

export const getFeeDetail = (id) => request({
  url: '/api/fee/detail',
  method: 'get',
  params: {
    id
  }
});

/**
 * 获取缴费的学年学期
 * feeYearId与year、term二选一传递
 * @param feeYearId feeYearId
 * @param year
 * @param term
 * @returns {*}
 */
export const getSquadColumns = (mainProcessId) => request({
  url: '/api/fee/squad-columns',
  method: 'get',
  params: {
    mainProcessId,
  }
});

export const uploadFile = (file) => {
  let config = {
    headers:{'Content-Type':'multipart/form-data'} //这里是重点，需要和后台沟通好请求头
  };
  return axios.post('/api/blade-file/uploadfile/commonUpload',file,config);

}

//获取用户的数据范围
export const getUserInfoScope = () => request({
  url: '/api/blade-user/user-info-scope',
  method: 'get',
  params: {
  }
});

export const auditFee = (id,status) => request({
  url: '/api/fee/audit-status',
  method: 'post',
  params: {
    id,
    status
  }
});


export const getLeaderSta = (params) => request({
  url: '/api/sta/leader',
  method: 'get',
  params: {
    ...params
  }
});



export const getProcessPage = (current, size, params) => request({
  url: '/api/newstudent/process/year-page',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  }
});


export const getProcessYears = () => request({
  url: '/api/newstudent/process/process-years',
  method: 'get',
  params: {
  }
});


/**
 * 根据学生ids，学年学期id获取fee表中的学生数据
 * @param ids 学生id
 * @param feeYearId 学年学期id
 * @returns {AxiosPromise}
 */
export const getStudents = (ids,feeYearId) => request({
  url: '/api/fee/students',
  method: 'post',
  params: {
    ids,
    feeYearId
  }
});

export const getStudentDataExport = (entranceYear,squadId,preview) => request({
  url: '/api/new-student/getStudentDataExport',
  method: 'get',
  params: {
    entranceYear,
    squadId,
    preview
  }
});

//
export const getAuditMainProcessPage = (current, size, params) => request({
  url: '/api/newstudent/mainprocess/audit-page',
  method: 'get',
  params: {
    ...params,
    current,
    size,
  }
});

export const getProcessListByMainId = (processMainId) => request({
  url: '/api/newstudent/process/list-by-main-id',
  method: 'get',
  params: {
    processMainId
  }
});


//
export const downloadByFile = ({id,url}) => {
  return request({
    url: '/api/blade-file/uploadfile/download-file',
    method: 'get',
    responseType: 'blob',
    params: {
      id,
      url
    }
  })
}
